<template>
  <div>
    <div class="flex items-center mx-2">
      <label :for="id || randomId" class="item__checkbox">
        <span class="checkbox__box" tabindex="0"> </span>
        <input
          :id="id || randomId"
          type="checkbox"
          class="checkbox__input visually-hidden"
          :checked="localValue === trueValue"
          @change.prevent.stop="changeCheckbox($event)"
        />
        <svg class="checkbox__icon" width="14" height="14">
          <use href="@/components/formGenerator/builder/assets/sprite.svg#icon-check"></use>
        </svg>
      </label>
      <span v-text="text" class="label" v-if="text"></span>
      <span v-else class="label"><slot></slot></span>
    </div>
    <div class="hint" v-if="description">
      {{ description }}
    </div>
  </div>
</template>

<script>
import { uuid } from "vue-uuid";
export default {
  name: "base-checkbox",
  props: {
    id: {
      type: [Boolean, String, Number],
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    value: {
      type: [Boolean, String, Number],
      default() {
        return null;
      },
    },
    trueValue: {
      type: [Boolean, String, Number],
      default() {
        return true;
      },
    },
    falseValue: {
      type: [Boolean, String, Number],
      default() {
        return null;
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    default: {
      type: [Boolean, String, Number],
      default() {
        return null;
      },
    },

    modelValue: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
    },
  },
  emits: ["update:modelValue", "valueChange"],
  data() {
    return {
      randomId: "",
      localValue: null,
    };
  },
  watch: {
    modelValue(newVal) {
      this.localValue = newVal;
    },
    value(newVal) {
      this.localValue = newVal;
    },
  },
  methods: {
    changeCheckbox() {
      if(this.localValue === this.trueValue) {
          this.localValue = this.falseValue;
      }
      else {
        this.localValue = this.trueValue;
      }
      this.$emit("valueChange", this.localValue, this.id);
      this.$emit("update:modelValue", this.localValue);
      // console.log("changeCheckbox", this.localValue, this.id);
    },
  },
  created() {   
    if (this.value !== null || this.value !== undefined) {  
      this.localValue = this?.value;
    }    
    this.randomId = uuid.v4().slice(0, 5);
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.item__checkbox {
  width: 17px;
  height: 17px;
  position: relative;
  /* margin-left: 4px; */
  cursor: pointer;
}

.checkbox__input {
  width: 16px;
  height: 16px;
}

.checkbox__box {
  position: absolute;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(238, 238, 241);
  border: 2px solid rgb(102, 102, 135);
}

.checkbox__box:hover,
.checkbox__box:focus {
  border: 2px solid var(--accent-color);
}

.checkbox__input:checked + .checkbox__icon {
  display: block;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
}

.checkbox__icon {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: var(--accent-color);
  pointer-events: none;
}

.label {
  margin-left: 4px;
}

.hint {
  color: rgb(102, 102, 135);
  font-size: 0.75rem;
  line-height: 1.33;
}
</style>
