<template>
  <!-- <label
    v-if="labelName"
    class="v-label text-subtitle-1 font-weight-medium pb-1 text-lightText"
    >{{ labelName }}
  </label> -->
  <div
    class="v-input v-input--horizontal v-input--density-comfortable v-input--dirty v-text-field"
  >
    <div class="v-input__control">
      <div
        class="v-field v-field--active v-field--dirty v-field--no-label v-field--variant-outlined v-theme--BLUE_THEME"
        role="textbox"
      >
        <div class="v-field__overlay"></div>
        <div class="v-field__loader">
          <div
            class="v-progress-linear v-theme--BLUE_THEME"
            role="progressbar"
            aria-valuemin="0"
            aria-valuemax="100"
            style="height: 0px; --v-progress-linear-height: 2px"
          >
            <div
              class="v-progress-linear__background bg-primary"
              style="width: 100%"
            ></div>
            <div class="v-progress-linear__indeterminate">
              <div
                class="v-progress-linear__indeterminate long bg-primary"
              ></div>
              <div
                class="v-progress-linear__indeterminate short bg-primary"
              ></div>
            </div>
          </div>
        </div>

        <div class="v-field__field" data-no-activator="">
          <label class="v-label v-field-label" for="input-115"></label>
          <span v-if="labelName" class="value__placeholder" >{{ labelName }}</span>
          <input
            size="1"
            class="v-field__input"
            :value="value"
            :type="type"
            :name="name"
            :class="variant"
            :autofocus="autofocus"
            :placeholder="placeholder"
            @input="updateValue($event)"
            @keypress.enter="emitKeypress"
            :autocomplete="autocomplete"
            :disabled="disabled"
            ref="baseInput"
            :step="stepNumber"
          />
        </div>
        <!-- <div class="v-field__outline">
          <div class="v-field__outline__start"></div>
          <div class="v-field__outline__end"></div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseInput",
  props: {
    value: { type: [String,Number], default: "" },
    type: { type: String, default: "text" },
    name: { type: String },
    labelName: { type: String },
    placeholder: { type: String },
    autofocus: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    variant: { type: String },
    inputWidth: { type: String, default: "" },
    debounceTime: { type: Number, default: 200 },
    autocomplete: { type: String, default: "off" },
    step: {type: Number, default: 1},
  },
  emits: ["update:value", "keypress"],
  data() {
    return {
      debounce: null,
      stepNumber: 1,
    };
  },
  methods: {
    updateValue(e) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.$emit("update:value", e.target.value);
      }, this.debounceTime);
    },
    focusOnInput() {
      this.$refs.baseInput.focus();
    },
    emitKeypress(e){
       this.$emit("keypress", e);
    }
  },
  mounted() {
    if (this.autofocus) this.focusOnInput();
    if( (this.type == 'number') && (this.step != undefined)){
      this.stepNumber = this.step;
    }
  },
};
</script>

<style lang="scss" scoped>
.v-text-field input{
  background: white;
}


.value__placeholder {
  position: absolute;
  text-transform: none;
  top: 0;
  left: 0.5rem;
  transform: translateY(-50%);
  font-size: 0.7rem;
  line-height: 1;
  background-color: var(--bms-bg, #ffffff);
  border-radius: 4px;
  padding: 2px;
  color: #000;
  transition: transform 250ms ease-out, top 250ms ease-out, font-size 250ms ease-out; 
}
.v-field__input{
  padding: 0.4rem;
  min-height: calc(
      2 * var(--bms-border-width, 1px) + var(--bms-font-size, 1rem) *
        var(--bms-line-height, 1.375) + 2 * var(--ms-py, 0.5rem)
    );
  border: var(--bms-border-width, 1px) solid var(--bms-border-color, #000);
  border-radius: var(--bms-border-radius, 4px);
  &:hover {
    border-color: var(--bms-border-color-hover, #acacff);
  }
}

</style>
