<template>
  <div class="v-row" v-if="!isLoading">
    <div class="v-col-lg-12"
         style="padding: 10px 12px 12px 12px;">
      <settings v-if="!isLoading"
                :device="settingListDevice"
                :item="settingListItem"
                @event-setting="confirmSetting"></settings>

    </div>

    <div class="v-col-lg-12">
      <indicators v-if="!isLoading"
                  :indicators="filterIndicators"
                  @event-indicator-click="handleIdicatorClick"></indicators>
    </div>

    <div v-if="!isLoading" class="v-col-sm-12 v-col-lg-6 v-col-12">
      <div class="v-theme--BLUE_THEME v-card--density-default elevation-10 rounded-md v-card--variant-elevated">
        <div class="v-card-text w-100 custom-card">
          <chart-price v-if="!isLoading"
            :_series="seriesChartsPrice"
            :_categories="categoriesChartsPrice"
            :_title="translates.chartPrice.title"
            :_yaxisText="translates.chartPrice.yaxis"
            @event-click-chart="handleChartClick"
          >
          </chart-price>
        </div>
      </div>
    </div>

    <div v-if="!isLoading" class="v-col-sm-12 v-col-lg-6 v-col-12">
      <div class="v-theme--BLUE_THEME v-card--density-default elevation-10 rounded-md v-card--variant-elevated">
        <div class="v-card-text w-100 custom-card">
          <charts-quantity-check v-if="!isLoading"
            :_series="seriesChartsQuantityCheck"
            :_categories="categoriesChartsQuantityCheck"
            :_title="translates.chartsQuantityCheck.title"
            :_yaxisText="translates.chartsQuantityCheck.yaxis"
          >
          </charts-quantity-check>
        </div>
      </div>
    </div>

    <div v-if="!isLoading" class="v-col-sm-12 v-col-lg-6 v-col-12">
      <div class="v-theme--BLUE_THEME v-card--density-default elevation-10 rounded-md v-card--variant-elevated">
        <div class="v-card-text w-100 custom-card">
          <last-bar-sales v-if="!isLoading"
            :_series="seriesLastBarSales"
            :_categories="categoriesLastBarSales"
            :_title="translates.lastBarSales.title"
            :_yaxisText="translates.lastBarSales.yaxis"
            @event-click-chart="handleChartClick">
          </last-bar-sales>
        </div>
      </div>
    </div>

    <div v-if="!isLoading" class="v-col-sm-12 v-col-lg-6 v-col-12">
      <div class="v-theme--BLUE_THEME v-card--density-default elevation-10 rounded-md v-card--variant-elevated">
        <div class="v-card-text w-100 custom-card">
          <bar-price v-if="!isLoading"
            :_series="seriesBarPrice"
            :_categories="categoriesBarPrice"
            :_title="translates.barPrice.title"
            :_yaxisText="translates.barPrice.yaxis"
            @click="handleBarClick($event,'barPrice')">
          </bar-price>
        </div>
      </div>
    </div>

    <div v-if="!isLoading" class="v-col-sm-12 v-col-lg-6 v-col-12">
      <div class="v-card v-theme--BLUE_THEME v-card--density-default elevation-10 rounded-md v-card--variant-elevated">
        <div class="v-card-text w-100 custom-card">
          <treemap-charts v-if="!isLoading"
            :_series="seriesTreemapCharts"
            :_categories="categoriesTreemapCharts"
            :_title="translates.treeMapCharts.title"
            :_yaxisText="translates.treeMapCharts.yaxis">
          </treemap-charts>
        </div>
      </div>
    </div>

    <div v-if="!isLoading" class="v-col-sm-12 v-col-lg-6 v-col-12">
      <div class="v-theme--BLUE_THEME v-card--density-default elevation-10 rounded-md v-card--variant-elevated">
        <div class="v-card-text w-100 custom-card">
          <bar-remaining-goods v-if="!isLoading"
            :_series="seriesBarPlanogramma"
            :_categories="categoriesBarPlanogramma"
            :_title="translates.barRemainingGoods.title"
            @click="handleBarClick($event,'barRemainingGoods')">
          </bar-remaining-goods>
        </div>
      </div>
    </div>

    <div v-if="!isLoadingDonout" class="v-col-sm-12 v-col-lg-6 v-col-12">
      <div class="v-theme--BLUE_THEME v-card--density-default elevation-10 rounded-md v-card--variant-elevated">
        <div class="v-card-text w-100 custom-card">
          <donut-sales v-if="!isLoadingDonout"
            :_series="seriesDonutSales"
            :_categories="categoriesDonutSales"
            :_title="translates.donutSales.title">
          </donut-sales>
        </div>
      </div>
    </div>

    <div v-if="!isLoading" class="v-col-sm-12 v-col-lg-6 v-col-12">
      <div class="v-theme--BLUE_THEME v-card--density-default elevation-10 rounded-md v-card--variant-elevated">
        <div class="v-card-text w-100 custom-card">
          <donut-sales-item v-if="!isLoading"
            :_series="seriesDonutSalesItem"
            :_categories="categoriesDonutSalesItem"
            :_title="translates.donutSalesItem.title"></donut-sales-item>
        </div>
      </div>
    </div>

    <div v-if="!isLoadingReport" class="v-col-sm-12 v-col-lg-6 v-col-12">
      <div class="v-theme--BLUE_THEME v-card--density-default elevation-10 rounded-md v-card--variant-elevated">
        <div class="v-card-text w-100 custom-card">
          <report  v-if="!isLoadingReport"
            :title="$t('reports.table-sale-day.title')"
            :column="dataReportItemSales.colums"
            :statusBar="dataReportItemSales.statusBar"
            :row="dataReportItemSales.rows"></report>
        </div>
      </div>
    </div>

    <div v-if="!isLoading" class="v-col-sm-12 v-col-lg-12 v-col-12">
      <div class="v-card v-theme--BLUE_THEME v-card--density-default elevation-10 rounded-md v-card--variant-elevated">
        <div class="v-card-text w-100 custom-card">
          <report-sales-month v-if="!isLoading" :_currency="currency"></report-sales-month>
        </div>
      </div>
    </div>

    <div v-if="!isLoading" class="v-col-sm-12 v-col-lg-12 v-col-12">
      <div class="v-card v-theme--BLUE_THEME v-card--density-default elevation-10 rounded-md v-card--variant-elevated">
        <div class="v-card-text w-100 custom-card">
          <report-sales v-if="!isLoading" :_currency="currency"></report-sales>
        </div>
      </div>
    </div>
  </div>
  <base-modal v-model="showInstanceDetail"
              :showOwerlay="true"
              :top="innerPositionTop"
              @modalClose="closeModal">
    <div class="d-flex justify-content-between align-center mx-2 h-100">
      <div class="d-flex align-center">
        <h2>{{ $t(titleModal,{currency: currency}) }}</h2>
      </div>
      <div class="d-flex align-center" style="margin: 5px;">
        <base-button
            @click="closeModal"
            className="v-btn v-theme--BLUE_THEME bg-error v-btn--density-default v-btn--size-default v-btn--variant-flat mr-2"
            style="cursor: pointer;"
            v-tooltip:bottom="$t('tips.close')"
        >{{ $t("buttons.close") }}
        </base-button>
      </div>
    </div>
    <report-remaining-good v-if="showInstanceDetail"></report-remaining-good>
  </base-modal>
  <base-modal v-model="showChartDetail"
              :showOwerlay="true"
              :top="innerPositionTop"
              @modalClose="closeModal">
    <div class="d-flex justify-content-between align-center mx-2 h-100">
      <div class="d-flex align-center">
        <h2>{{ $t(titleModal,{currency: currency}) }}</h2>
      </div>
      <div class="d-flex align-center" style="margin: 5px;">
        <base-button
            @click="closeModal"
            className="v-btn v-theme--BLUE_THEME bg-error v-btn--density-default v-btn--size-default v-btn--variant-flat mr-2"
            style="cursor: pointer;"
            v-tooltip:bottom="$t('tips.close')"
        >{{ $t("buttons.close") }}
        </base-button>
      </div>
    </div>
    <default-chart v-if="showChartDetail"
      :_series="seriesChartsPriceAll"
      :_categories="categoriesChartsPriceAll"
      :_currency="currency"
      :typeChart="typeChart">
    </default-chart>
  </base-modal>
  <spinner v-if="spinnerShow"></spinner>
</template>
<script>
import Settings from "./dashboard/Settings";
import Indicators from "./dashboard/Indicators";
import ChartPrice from "./dashboard/ChartsPrice";
import ChartsQuantityCheck from "./dashboard/ChartsQuantityCheck";
import DonutSales from "./dashboard/DonutSales";
import DonutSalesItem from "./dashboard/DonutSalesItem";
import BarPrice from "./dashboard/BarPrice";
import LastBarSales from "./dashboard/LastBarSales";
import TreemapCharts from "./dashboard/TreemapCharts";
import ReportSalesMonth from "./dashboard/ReportSalesMonth";
import ReportSales from "./dashboard/ReportSales";
import ReportRemainingGood from "./dashboard/ReportRemainingGood.vue";
import BarRemainingGoods from "./dashboard/RemainingGoodsBar.vue";
import DefaultChart from "./dashboard/DefaultChart.vue";
import Report from "./dashboard/Report.vue";

import {mapActions, mapState} from "pinia";
import {useDashboardStore} from "../stores/dashbordStore";
import { useUserStore } from "@/stores/userStore";

import Spinner from "./Spinner.vue";
import BaseModal from "./BaseModal.vue";
import BaseButton from "@/components/BaseButton.vue";

export default {
  name: "dashboard",
  props: {},
  components: {
    ReportRemainingGood,
    BarRemainingGoods,
    Settings,
    Indicators,
    ChartPrice,
    ChartsQuantityCheck,
    DonutSales,
    DonutSalesItem,
    BarPrice,
    TreemapCharts,
    Report,
    ReportSalesMonth,
    ReportSales,
    LastBarSales,
    Spinner,
    BaseModal,
    BaseButton,
    DefaultChart
  },
  data() {
    return {
      
      isLoading: true,
      isLoadingDonout:true,
      isLoadingReport:true,
      spinnerShow: true,
      payload: null,
      showInstanceDetail: false,
      showChartDetail: false,
      titleModal: '',
      globalFilteredByName: [],
      globalUniqueNamesItem: [],
      dataLocalCharts: [],

      writeOffIndicator: null,
      dataLocalWriteOff: [],
      uniqNamesWriteOff: [],
      filterIndicators: '',
      chartsSeriesPrice: [],
      chartsOptionsPrice: null,
      datePicker: [],
      countSelectedDay: 0,
      elementHtml: document.getElementById('html-box'),
      innerPositionTop: "75px",
      typeChart:'line',

      dataReportItemSales: {},

      settingListDevice: [],
      settingListItem: [],
      seriesChartsPrice: [],
      seriesChartsPriceAll: [],
      seriesChartsQuantityCheck: [],
      seriesDonutSales: [],
      seriesBarPrice: [],
      seriesLastBarSales: [],
      seriesDonutSalesItem: [],
      seriesTreemapCharts: [],
      seriesReportSalesMonth: [],
      seriesBarPlanogramma: [],

      categoriesChartsPrice: [],
      categoriesChartsPriceAll: [],
      categoriesChartsQuantityCheck: [],
      categoriesBarPrice: [],
      categoriesLastBarSales: [],
      categoriesDonutSales: [],
      categoriesDonutSalesItem: [],
      categoriesTreemapCharts: [],
      categoriesBarPlanogramma: [],

      colums: [],
      rows: [],

      gotWriteOffData: false,
      gotMainData: false,
    };
  },
  computed: {
    ...mapState(useDashboardStore, ['dataDashboard', 'dataPlanogramma', 'settingDashboard',
    'columsSales', 'dataSales', 'selectedDevice', 'selectedItem','dataWriteOff']),
    ...mapState(useUserStore, ['user']),
    currency(){
      let curr = '';
      if(this.dataPlanogramma.length > 0){
        for (let i = 0; i < this.dataPlanogramma.length; i++) {
          if(this.dataPlanogramma[0].currency){
            curr = this.dataPlanogramma[0].currency;
            break;
          }
        }
        
      }
      return curr
    },
    translates(){
      return {
        chartPrice: {
          title: this.$t('dashboard.chart.sales-last-week',{currency: this.currency}),
          yaxis: this.$t('dashboard.chart.sum',{currency: this.currency}),
        },
        chartsQuantityCheck: {
          title: this.$t('dashboard.chart.sales-check-hours'),
          yaxis: this.$t('dashboard.indicators.checks'),
        },
        lastBarSales:{
          title: this.$t('dashboard.chart.sales',{currency: this.currency}),
          yaxis: this.$t('dashboard.chart.sum',{currency: this.currency})
        },
        barPrice:{
          title: this.$t('dashboard.chart.sales-week'),
          yaxis: this.$t('dashboard.chart.sum',{currency: this.currency})
        },
        treeMapCharts:{
          title: this.$t('dashboard.chart.top',{currency: this.currency}) 
        },
        barRemainingGoods:{
          title: this.$t('dashboard.chart.remaining',{currency: this.currency}),
          yaxis: this.$t('dashboard.chart.sum',{currency: this.currency})
        },
        donutSales:{
          title: this.$t('dashboard.chart.sales-point',{currency: this.currency}) 
        },
        donutSalesItem:{
          title: this.$t('dashboard.chart.sales-category',{currency: this.currency})
        }
      }
    },
  },
  created() {
  },
  mounted() {
    this.getDataPlan().then(() => {
      this.setDataSettingsDashboard();
      this.getDateDashboard();
    });

  },
  methods: {

      /*
        Initial load:
        - Load the planogram (not tied to a specific date, just filling data)
        - Load graphics
            - Request to the server (Date: last week, no device filtering, all available selected)
        - Load settings for the graphics (devices, categories)
      */
    ...mapActions(useDashboardStore, ['getSettingsDashboard', 'setSettingDashboard', 'postMainDashboard','postReplanishment', 'getPlanogramma', 'postPlanogramma', 'setDataForReportSalesMonth', 'setDataForReportSales',
      'setDataForReportRemainingGood', 'setDataForReportSalesWeek', 'getDataReportItemSales',
      'setDataForIndicatorReportSales', 'setDataForWriteOffReport', 'setDataForIndicatorReportCost', 'setDataForReportRemainingGoodFilters',
      'setDataForReportAverageСheck', 'resetDate', 'setDate']),

    // Get data and create chart Planogramma
    async getDataPlan() {
      let dataRequest = this.settingDashboard;
      if(!dataRequest.category || dataRequest.category.length < 1){
        delete dataRequest ['category'];
      }
      if(!dataRequest.conveniq || dataRequest.conveniq.length < 1){
        delete dataRequest ['conveniq'];
      }
      await this.postPlanogramma(this.settingDashboard).then(() => {
        this.setBarPlanogramma();
      });
    },

    // Set setting dashboard (device, item)
    async setDataSettingsDashboard() {
      await this.setDataSettings();
    },

    async getDateDashboard(startData = undefined, endData = undefined) {

      this.gotWriteOffData = false;
      this.gotMainData = false;

      if (startData === undefined && endData === undefined) {
        let currentDate = new Date();
        let weekAgo = new Date();

        weekAgo.setDate(weekAgo.getDate() - 7);
        weekAgo.setHours(0, 0, 0, 0);

        startData = weekAgo.toISOString().split('T')[0] + 'T00:00:00';
        endData = currentDate.toISOString().split('T')[0] + 'T23:59:59';
      }

      this.countSelectedDay = this.getCountDay(startData, endData);

      let entity = `start_date=${startData}&end_date=${endData}`
      this.setDate(startData, endData);

      this.settingDashboard.startData = startData;
      this.settingDashboard.endData = endData;

      let dataRequest = this.settingDashboard;
      if(!dataRequest.category || dataRequest.category.length < 1){
        delete dataRequest ['category'];
      }
      if(!dataRequest.conveniq || dataRequest.conveniq.length < 1){
        delete dataRequest ['conveniq'];
      }

      this.postMainDashboard(entity, dataRequest).then(() => {
        this.dataLocalCharts = this.dataDashboard;
        this.prepareDataIndicators(this.dataLocalCharts);
      });

      this.postReplanishment(entity, dataRequest).then(() => {
        this.prepareDataWriteOff(this.dataWriteOff);
      });
    },

    async getNewDateForDashboard() {
      this.gotWriteOffData = false;
      this.gotMainData = false;

      this.countSelectedDay = this.getCountDay(this.settingDashboard.startData, this.settingDashboard.endData);
      let entity = `start_date=${this.settingDashboard.startData}&end_date=${this.settingDashboard.endData}`
      this.setDate(this.settingDashboard.startData, this.settingDashboard.endData);

      let dataRequest = this.settingDashboard;
      if(!dataRequest.category || dataRequest.category.length < 1){
        delete dataRequest ['category'];
      }
      if(!dataRequest.conveniq || dataRequest.conveniq.length < 1){
        delete dataRequest ['conveniq'];
      }

      this.postMainDashboard(entity, dataRequest).then(() => {
        this.dataLocalCharts = this.dataDashboard;
        this.prepareDataIndicators(this.dataLocalCharts, this.settingDashboard.conveniq, this.settingDashboard.category);
      });

      this.postReplanishment(entity, dataRequest).then(() => {
        this.prepareDataWriteOff(this.dataWriteOff, this.settingDashboard.conveniq, this.settingDashboard.category);
      });
    },

    prepareDataIndicators(arrayData, uniqueNamesDevice = undefined, uniqueNamesItem = undefined) {
      return new Promise((resolve) => {

        // an array of hardware names to filter
        if (uniqueNamesDevice === undefined) {
          uniqueNamesDevice = this.getDataByFilterDevice(arrayData);
          //this.settingDashboard.conveniq = uniqueNamesDevice;
        }else {
          uniqueNamesDevice = this.settingDashboard.conveniq;
        }

        // an array of nomenclature names to filter
        if (uniqueNamesItem === undefined) {
          uniqueNamesItem = this.getDataByFilterItem(arrayData);
          //this.settingDashboard.category = uniqueNamesItem;
        }else {
          uniqueNamesItem = this.settingDashboard.category;
        }

        // get a new array with selection by equipment
        const filteredByDevice = this.setDeviceFilter(arrayData, uniqueNamesDevice);

        // get a new array with selection by nomenclature
        const filteredByName = this.setItemFilter(filteredByDevice, uniqueNamesItem);


        // set the values of the indicators according to the filtered array.
        this.setInicatorsByFilter(filteredByName, uniqueNamesItem);

        // a schedule of sales and cost per day was formed
        if (this.countSelectedDay < 10) {
          this.setChartsPrice(filteredByName, false);
        }

        this.setChartsQuantityCheck(filteredByName);

        if (this.countSelectedDay < 10) {
          this.setLastBarSales(filteredByName);
        }

        this.setBarPrice(filteredByName);

        if (this.settingDashboard.conveniq && this.settingDashboard.conveniq.length > 1){
          this.setDonutSales(filteredByName);
          this.isLoadingDonout = false;
          this.isLoadingReport = true;
        } else {
          this.setReportItemSales(filteredByName);
          this.isLoadingReport = false;
          this.isLoadingDonout = true;
        }

        this.setDonutSalesItem(filteredByName);


        this.setTreemapCharts(filteredByName);

        this.setDataForReportSalesMonth(filteredByName);

        this.setDataForReportSales(filteredByName, this.$t('dashboard.chart.month'));

        this.globalFilteredByName = filteredByName;
        this.globalUniqueNamesItem = uniqueNamesItem;

        this.setBarPlanogramma();

        this.isLoading = false;
        this.spinnerShow = false;

        resolve();
      }).then(
          () => {
            this.gotMainData = true;
            this.addIndicatorWO();
            this.spinnerShow = false;
          }
      )


    },
    prepareDataWriteOff(arrayData, uniqueNamesDevice = undefined, uniqueNamesItem = undefined) {
      return new Promise((resolve) => {

        // an array of hardware names to filter
        if (uniqueNamesDevice === undefined) {
          uniqueNamesDevice = this.getDataByFilterDevice(arrayData);
          //this.settingDashboard.conveniq = uniqueNamesDevice;
        }else {
          uniqueNamesDevice = this.settingDashboard.conveniq;
        }

        // an array of nomenclature names to filter
        if (uniqueNamesItem === undefined) {
          uniqueNamesItem = this.getDataByFilterItem(arrayData);
          //this.settingDashboard.category = uniqueNamesItem;
        }else {
          uniqueNamesItem = this.settingDashboard.category;
        }

        // get a new array with selection by equipment
        const filteredByDevice = this.setDeviceFilter(arrayData, uniqueNamesDevice);
        // get a new array with selection by nomenclature
        const filteredByName = this.setItemFilter(filteredByDevice, uniqueNamesItem);


        // set the values of the indicators according to the filtered array.
        let sumWriteOff = filteredByName.reduce((accumulator, currentItem) => {
          return accumulator + currentItem.summa;
        }, 0);
          this.writeOffIndicator = {
            type: "write-off",
            title: 'dashboard.indicators.write-off',
            value: sumWriteOff,
            currency: this.currency
          }

        this.dataLocalWriteOff = filteredByName;
        this.uniqNamesWriteOff = uniqueNamesItem;

        this.isLoading = false;
        this.spinnerShow = false;

        resolve();
      }).then(
          () => {
            this.gotWriteOffData = true;
            this.addIndicatorWO();
            this.spinnerShow = false;
          }
      )


    },
    addIndicatorWO(){
      if(this.writeOffIndicator && this.gotMainData && this.gotWriteOffData){
        this.gotMainData = false;
        this.gotWriteOffData = false;

        this.filterIndicators.push(this.writeOffIndicator);
        this.writeOffIndicator = null;
      }
    },

    getCountDay(startData, endData) {
      let startDate = new Date(startData);
      let endDate = new Date(endData);

      let timeDiff = endDate.getTime() - startDate.getTime();

      let daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

      return daysDiff;
    },

    clearSettings() {
      this.settingDashboard = {};
      this.columsSales = [];
      this.dataSales = [];
      this.getDataPlan().then(() => {
        this.setDataSettingsDashboard();
        this.getDateDashboard();
      });
    },

    confirmSetting(payload) {
      this.spinnerShow = true;
      const {event, type, listSelected, dateStart, dateEnd} = payload;
      switch (event) {
        case 'dropdown':
          setTimeout(() => {
            this.changeMarket(listSelected, type);
            this.getDataPlan();
          }, 10);
          break;
        case 'date':
          this.settingDashboard.startData = dateStart;
          this.settingDashboard.endData = dateEnd;
          this.getNewDateForDashboard(dateStart, dateEnd);
          break;
        case 'clear':
          this.clearSettings();
          break;
        default:
          break;
      }
    },

    async changeMarket(data, type) {
      if (type === 'device') {
        await this.setSettingDashboard({
          device: data,
          item: this.settingDashboard.category, //this.selectedItem,
        });
        this.getNewDateForDashboard();

      } else if (type === 'item') {
        this.setSettingDashboard({
          device: this.settingDashboard.conveniq, //this.selectedDevice,
          item: data,
        });
        this.getNewDateForDashboard();
      }
    },
    async setDataSettings() {
      this.getSettingsDashboard().then((data)=>{
        const device = data.conveniq.reduce((acc, item) => {
          const dev = item.name.trim();
          if (!acc[dev]) {
            acc[dev] = {
              id: dev,
              checked: false,
              title: dev
            };
          }
          return acc;
        }, []);

        this.settingListDevice = Object.values(device);

        const categories = data.categories.reduce((acc, item) => {
          const category = item.name.trim();
          if (!acc[category]) {
            acc[category] = {
              id: category,
              checked: false,
              title: category
            };
          }
          return acc;
        }, []);
        

        this.settingListItem = Object.values(categories);
      });
    },

    setDeviceFilter(arrayData, arrayDevice) {
      return arrayData.filter(element => arrayDevice.includes(element.nameDevice));
    },
    setItemFilter(arrayData, arrayItem) {
      return arrayData.filter(element => arrayItem.includes(element.name));
    },
    getDataByFilterDevice(data) {

      const uniqueNamesDevice = data.reduce((acc, device) => {
        if (!acc.includes(device.nameDevice)) {
          acc.push(device.nameDevice);
        }
        return acc;
      }, []);

      /*this.settingListDevice = this.settingListDevice.map(item => ({
        ...item,
        checked: uniqueNamesDevice.includes(item.id)
      }));*/

      return uniqueNamesDevice;
    },
    getDataByFilterItem(data) {
      const uniqueNamesItem = data.reduce((acc, item) => {
        if (!acc.includes(item.name)) {
          acc.push(item.name);
        }
        return acc;
      }, []);

      /*this.settingListItem = this.settingListItem.map(item => ({
        ...item,
        checked: uniqueNamesItem.includes(item.id)
      }));*/

      return uniqueNamesItem;
    },

    setInicatorsByFilter(arrayData, uniqueNamesItem) {
      this.filterIndicators = [];
      const quidCheckArray = arrayData.filter(item => uniqueNamesItem.includes(item.name)).map(item => item.quidCheck);
      //const uniqueCustomerIds = [...new Set(arrayData.map(item => item.customer_id))];

      let averageCheck = 0;
      const uniqueQuidChecks = [...new Set(quidCheckArray)];
      const sumOfSumma = arrayData.reduce((accumulator, currentItem) => {
        return accumulator + currentItem.summa;
      }, 0);
      const sumOfCost = arrayData.reduce((accumulator, currentItem) => {
        return accumulator + (currentItem.priceBuy * currentItem.quantity);
      }, 0);
      if (sumOfSumma > 0 && uniqueQuidChecks.length > 0) {
        averageCheck = Math.round(sumOfSumma / uniqueQuidChecks.length);
      }
      
      this.filterIndicators.push(
          {
            type: "sales",
            title: 'dashboard.indicators.sales',
            value: sumOfSumma,
            currency: this.currency//"грн."
          },
          {
            type: "cost",
            title: 'dashboard.indicators.cost',
            value: Math.round(sumOfCost),
            currency: this.currency//"грн."
          },
          {
            type: "income",
            title: 'dashboard.indicators.income',
            value: Math.round(sumOfSumma - sumOfCost),
            currency: this.currency//"грн."
          },
          {
            type: "remaining",
            title: 'dashboard.indicators.remaining',
            value: this.getRemainingItems(),
            currency: this.currency//"грн."
          },
          {
            type: "checks",
            title: 'dashboard.indicators.checks',
            value: uniqueQuidChecks.length,
            currency: "шт."
          },
          {
            type: "average",
            title: 'dashboard.indicators.average',
            value: averageCheck,
            currency: this.currency//"грн."
          },
          /*{
            type: "clients",
            title: 'dashboard.indicators.clients',
            value: uniqueCustomerIds.length,
            currency: ""
          },*/
      );
    },
    getRemainingItems() {
      let filteredObjects = this.dataPlanogramma;
      if(this.settingDashboard.conveniq && this.settingDashboard.conveniq.length > 0){
        filteredObjects = this.dataPlanogramma.filter(item => this.settingDashboard.conveniq.includes(item.conveniq));
      }
      
      return filteredObjects.reduce((sum, obj) => sum + obj.current_amount, 0)
    },
    setChartsPrice(arrayData, all = false) {

      // Grouping and transforming the array
      const groupedData = arrayData.reduce((acc, obj) => {
        const {day, month, summa, priceBuy, quantity} = obj;

        // Calculate the coast
        const coast = priceBuy * quantity;

        // Create a unique key using day and month
        const key = `${day}-${month}`;

        // Check if the key already exists in the accumulator
        if (acc[key]) {
          // Accumulate the summa and coast for the existing key
          acc[key].summa += summa;
          acc[key].coast += coast;
          acc[key].day = parseInt(day);
          acc[key].month = parseInt(month);
        } else {
          // Create a new entry for the key
          acc[key] = {day, month, summa, coast};
        }

        return acc;
      }, {});

      const sortedData = Object.values(groupedData).sort((a, b) => {
        if (a.month !== b.month) {
          return a.month - b.month;
        } else {
          return a.day - b.day;
        }
      });

      // Converting the grouped data object to an array of values
      const result = Object.values(sortedData);

      if (!all){
        this.categoriesChartsPrice = [];
        this.categoriesChartsPrice = result.map((item) => parseInt(item.day) + " " + this.$t('dashboard.chart.day'));
      }else {
        this.categoriesChartsPriceAll = [];
        this.categoriesChartsPriceAll = result.map((item) => parseInt(item.day) + " " + this.$t('dashboard.chart.day'));
      }

      const summaArr = result.map((item) => Math.round(item.summa));
      const costArr = result.map((item) => Math.round(item.coast));

      let lineOne = {
        data: summaArr
      }

      let lineTwo = {
        data: costArr
      }

      if (!all){
        this.seriesChartsPrice = [];
        this.seriesChartsPrice.push(lineOne);
        this.seriesChartsPrice.push(lineTwo);
      }else {
        this.seriesChartsPriceAll = [];
        this.seriesChartsPriceAll.push(lineOne);
        this.seriesChartsPriceAll.push(lineTwo);
      }

    },
    setChartsQuantityCheck(arrayData) {
      // grouping and counting by "day", "idDevice" and "quidCheck" fields
      const result = this.groupAndCount(arrayData, 'countquidCheck','nameDevice','hour');

      // creating a new array of structures
      const newDataArray = this.createNewArray(result);

      this.categoriesChartsQuantityCheck = [];
      const uniqueHours = [];

      for (let i = 0; i < result.length; i++) {
        const currentHour = result[i].hour;

        if (!uniqueHours.includes(currentHour)) {
          uniqueHours.push(currentHour);
        }
      }

      uniqueHours.sort((a, b) => a - b);

      this.categoriesChartsQuantityCheck = uniqueHours.map((item) => item + " " + this.$t('dashboard.chart.hour'));

      this.seriesChartsQuantityCheck = [];
      this.seriesChartsQuantityCheck = newDataArray.map(item => {
        return Object.values(item)[0];
      });

    },
    setBarPrice(arrayData) {

      // Grouping and transforming the array
      const groupedData = arrayData.reduce((acc, obj) => {
        const {week, month, summa, priceBuy, quantity} = obj;

        // Calculate the coast
        const coast = priceBuy * quantity;

        // Check if the day already exists in the accumulator
        if (acc[week]) {
          // Accumulate the summa and coast for the existing day
          acc[week].week = parseInt(week);
          acc[week].month = parseInt(month);
          acc[week].summa += summa;
          acc[week].coast += coast;
        } else {
          // Create a new entry for the day
          acc[week] = {week, month, summa, coast};
        }

        return acc;
      }, {});


      // Converting the grouped data object to an array of values
      const result = Object.values(groupedData);

      this.categoriesBarPrice = [];
      this.categoriesBarPrice = result.map((item) => item.week + " " + this.$t('dashboard.chart.week'));

      this.seriesBarPrice = [];
      this.seriesBarPrice = [
        {
          name: this.$t('dashboard.chart.sales-title'),
          data: result.map(item => Math.round(item.summa)),
        },
        {
          name: this.$t('dashboard.chart.coast-title'),
          data: result.map(item => Math.round(item.coast)),
        }
      ];
    },
    setLastBarSales(arrayData) {
      // Grouping and transforming the array
      const groupedData = arrayData.reduce((acc, obj) => {
        const {day, month, summa, priceBuy, quantity} = obj;

        // Calculate the coast
        const coast = priceBuy * quantity;
        const key = `${day}-${month}`;

        // Check if the day already exists in the accumulator
        if (acc[key]) {
          // Accumulate the summa and coast for the existing day
          acc[key].day = parseInt(day);
          acc[key].month = parseInt(month);
          acc[key].summa += summa;
          acc[key].coast += coast;
        } else {
          // Create a new entry for the day
          acc[key] = {day, month, summa, coast};
        }

        return acc;
      }, {});

      const sortedData = Object.values(groupedData).sort((a, b) => {
        if (a.month !== b.month) {
          return a.month - b.month;
        } else {
          return a.day - b.day;
        }
      });


      // Converting the grouped data object to an array of values
      const result = Object.values(sortedData);

      this.categoriesLastBarSales = [];
      this.categoriesLastBarSales = result.map((item) => item.day + " " + this.$t('dashboard.chart.day'));

      this.seriesLastBarSales = [];
      this.seriesLastBarSales = [
        {
          name: this.$t('dashboard.chart.sales-title'),
          data: result.map(item => Math.round(item.summa))
        },
        {
          name: this.$t('dashboard.chart.coast-title'),
          data: result.map(item => Math.round(item.coast))
        }
      ];
    },
    setDonutSales(arrayData) {
      const donutSales = {
        series: [],
        label: [],
      };

      const devices = {};
      for (const item of arrayData) {
        if (devices[item.idDevice]) {
          devices[item.idDevice] += item.summa;
        } else {
          devices[item.idDevice] = item.summa;
          donutSales.label.push(item.nameDevice);
        }
      }

      for (const device in devices) {
        donutSales.series.push(devices[device]);
      }

      this.seriesDonutSales = [];
      this.categoriesDonutSales = [];

      this.seriesDonutSales = donutSales.series;
      this.categoriesDonutSales = donutSales.label;
    },
    async setReportItemSales(arrayData) {
      this.dataReportItemSales = [];
      this.dataReportItemSales = await this.getDataReportItemSales(arrayData);
    },
    setDonutSalesItem(arrayData) {
      const donutSalesItem = arrayData.reduce((acc, item) => {
        const existingItem = acc.find(i => i.label === item.name);
        if (existingItem) {
          existingItem.data += item.summa;
        } else {
          acc.push({
            label: item.name,
            data: item.summa,
          });
        }
        return acc;
      }, []);

      let sortedData = donutSalesItem.sort((a, b) => b.data - a.data);

      this.seriesDonutSalesItem = [];
      this.categoriesDonutSalesItem = [];

      this.seriesDonutSalesItem = sortedData.map(item => item.data);
      this.categoriesDonutSalesItem = sortedData.map(item => item.label);
    },
    setTreemapCharts(arrayData) {
      const summaByUniqueName = {};

      for (const item of arrayData) {
        const name = item.name;
        const summa = item.summa;

        if (summaByUniqueName[name]) {
          summaByUniqueName[name] += summa;
        } else {
          summaByUniqueName[name] = summa;
        }
      }

      const data = Object.entries(summaByUniqueName).map(([name, summa]) => ({x: name, y: summa}));
      this.seriesTreemapCharts = [];
      this.seriesTreemapCharts.push({data: data});
    },
    setBarPlanogramma() {
      let filteredData = [];
      if(this.settingDashboard.conveniq && this.settingDashboard.conveniq.length > 0){
        filteredData = this.dataPlanogramma.filter(item => this.settingDashboard.conveniq.includes(item.conveniq));
      } else {
        filteredData = this.dataPlanogramma;
      }

      this.categoriesBarPlanogramma = [];
      this.categoriesBarPlanogramma = [...new Set(filteredData.map(item => item.conveniq))];

      this.seriesBarPlanogramma = [];
      this.seriesBarPlanogramma = [
        {
          name: this.$t('dashboard.chart.currently-sum-load'),
          data: filteredData.map(item => item.current_amount)
        },
        {
          name: this.$t('dashboard.chart.max-sum-load'),
          data: filteredData.map(item => item.max_amount)
        }
      ];

    },
    // Group and count function
    groupAndCount(array, countField, groupField, groupVal) {
      let gr = {};
      gr[groupField] = [];
      gr[groupVal] = [];

      let grouped = array.reduce((acc, obj) => {
        if (!gr[groupField].includes(obj[groupField])) {
          gr[groupField].push(obj[groupField]);
        }
        if (!gr[groupVal].includes(obj[groupVal])) {
          gr[groupVal].push(obj[groupVal]);
        }

        const key = obj[groupField] + '-' + obj[groupVal];
        if (!acc[key]) {
            acc[key] = { [groupField]: obj[groupField], [groupVal]: obj[groupVal], [countField]: 1};
        } else {
            acc[key][countField]++;
        }
        return acc;
      }, {});

      gr[groupVal].sort((a, b) => a - b)

      //fill not existing rows of data
      let keys = '';
      for (let j = 0; j < gr[groupField].length; j++) {
        for (let i = 0; i < gr[groupVal].length; i++) {
          keys = gr[groupField][j] + '-' + gr[groupVal][i];
          if(!grouped[keys]){
            grouped[keys] = { [groupField]: gr[groupField][j], [groupVal]: gr[groupVal][i], [countField]: null };
          }
        }
      }

      const res = Object.values(grouped).sort((a, b) => a[groupVal] - b[groupVal]);

      return res;
    },
    createNewArray(groupedData) {
      let newArray = [];
      let idDeviceMap = {};

      groupedData.forEach(function (item) {
        let idDevice = item.nameDevice;
        let countquidCheck = item.countquidCheck;

        if (!idDeviceMap[idDevice]) {
          idDeviceMap[idDevice] = [];
        }

        idDeviceMap[idDevice].push(countquidCheck);
      });

      let index = 0;
      for (let key in idDeviceMap) {
        let lineKey = "line" + (index + 1);
        let newData = {
          [lineKey]: {
            name: key,
            data: idDeviceMap[key]
          }
        };
        newArray.push(newData);
        index++;
      }

      return newArray;
    },

    handleBarClick(event, type) {
      let value = event.target.getAttribute('val');
      let filteredData;
      let conveniqId;

      if (type === "barRemainingGoods") {
        this.titleModal = 'reports.table-remaining.title';
        filteredData = this.dataPlanogramma.filter(item => item.current_amount == value || item.max_amount == value);
        if (filteredData.length === 1) {
          conveniqId = filteredData[0].conveniq_id
        }
        this.setDataForReportRemainingGood(conveniqId);
        this.showInstanceDetail = true;
      } else if (type === "barPrice") {
        this.titleModal = 'reports.table-sales.title';
        value = parseInt(value);
        let targetIndex = -1;

        for (let i = 0; i < this.seriesBarPrice.length; i++) {
          const data = this.seriesBarPrice[i].data;
          const index = data.indexOf(value);
          if (index !== -1) {
            targetIndex = index;
            break;
          }
        }
        if (targetIndex !== -1) {
          let week = this.categoriesBarPrice[targetIndex];
          week = parseInt(week.match(/\d+/)[0]);
          this.setDataForReportSalesWeek(week, this.dataLocalCharts);
          this.showInstanceDetail = true;
        }
      }
    },
    handleIdicatorClick(type) {
      switch (type) {
        case "sales":
          this.setDataForIndicatorReportSales(this.globalFilteredByName, this.globalUniqueNamesItem);
          this.titleModal = 'reports.table-sales.title';
          this.showModal("table");
          break;
        case "write-off":
          this.setDataForWriteOffReport(this.dataLocalWriteOff, this.uniqNamesWriteOff);
          this.titleModal = 'reports.write-off.title';
          this.showModal("table");
          break;
        case "cost":
          this.setDataForIndicatorReportCost(this.globalFilteredByName, this.globalUniqueNamesItem);
          this.titleModal = 'reports.table-cost.title';
          this.showModal("table");
          break;
        case "remaining":
          this.setDataForReportRemainingGoodFilters();
          this.titleModal = 'reports.table-remaining.title';
          this.showModal("table");
          break;
        case "average":
          this.setDataForReportAverageСheck(this.globalFilteredByName, this.globalUniqueNamesItem);
          this.titleModal = 'reports.table-average.title';
          this.showModal("table");
          break;
        default:
          break;
      }
    },
    handleChartClick(type) {
      switch (type) {
        case "chartPrice":
          this.typeChart = 'line';
          this.setChartsPrice(this.globalFilteredByName, true);
          this.titleModal = 'reports.table-sales.title';
          if((this.seriesChartsPriceAll.length > 0) && (this.seriesChartsPriceAll[0].data && this.seriesChartsPriceAll[0].data.length > 0)){
            this.showModal("chart");
          }
          break;
        case "barLastPrice":
          this.typeChart = 'bar';
          this.setChartsPrice(this.globalFilteredByName, true);
          this.titleModal = 'reports.table-sales.title';
          if((this.seriesChartsPriceAll.length > 0) && (this.seriesChartsPriceAll[0].data && this.seriesChartsPriceAll[0].data.length > 0)){
            this.showModal("chart");
          }
          break;
        case "remaining":
          break;
        case "average":
          break;
        default:
          break;
      }
    },
    showModal(modal){
      this.elementHtml = document.getElementById('html-box');
      this.elementHtml.classList.add('modal-open');
      if (modal === 'table'){
        this.showInstanceDetail = true;
      }else if (modal === 'chart'){
        this.showChartDetail = true;
      }
      this.spinnerShow = false;
    },
    closeModal() {
      this.elementHtml.classList.remove('modal-open');
      this.showInstanceDetail = false;
      this.showChartDetail = false;
    },
  },

};
</script>
<style lang="scss" scoped>

.custom-card {
  background: none !important;
}

.modal-open {
  overflow: hidden;
}
</style>
