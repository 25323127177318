import { sendRequest } from "@/helpers";
//import { useUserStore } from "@/stores/userStore";

export default {
  async doRequest(link, method, data = "") {
    return await sendRequest(link, "post", data);
  },

  async getTopbarMenu() {
    if(localStorage.getItem("lang") == undefined){ localStorage.setItem("lang", 'en'); }
    return await sendRequest(`/api/v1/admin_panel/menu?locale=${localStorage.getItem("lang") ? localStorage.getItem("lang") : 'en'}`, "get", "");
  },

  async getEntityFields(entity) {
    if(localStorage.getItem("lang") == undefined){ localStorage.setItem("lang", 'en'); }
    
    //let ent = useUserStore().checkEnitty(entity);
    //if(ent){
    //  return ent;
    //} else {
      let res = await sendRequest(`/api/v1/admin_panel/schema/${entity}?locale=${localStorage.getItem("lang") ? localStorage.getItem("lang") : 'en'}`, "get", "");
      //useUserStore().addEntity(res, entity);
      return res;
    //}
  },

  async getEntityData(entity, instance) {
    return await sendRequest(`/api/v1/admin_panel/entity/${entity}/${instance}`, "get", "");
  },

  async getEntityItems(entity, params = null) {
    return await sendRequest(`/api/v1/admin_panel/get_entities/${entity}?relationship_data=false`, "post", params);
  },

  async getEntityItemsLimit(entity, skip, limit, params = null, page = false) {
    return await sendRequest(`/api/v1/admin_panel/get_entities/${entity}?relationship_data=false&skip=${skip}&limit=${limit}&page_data=${page}`, "post", params);
  },

  async updateEntityItem(entity, data) {
    return await sendRequest(`/api/v1/admin_panel/entity/${entity}/${data?.id}`, "put", data);
  },

  async deleteEntityInstance(entity, id) {
    return await sendRequest(`/api/v1/admin_panel/entity/${entity}/${id}`, "delete", "");
  },

  async createEntityItem(entity, data) {
    return await sendRequest(`/api/v1/admin_panel/entity/${entity}`, "post", data);
  },
  
  async getShelfPhoto(id_shelf) {
    return await sendRequest(`/api/current_shelf_image/${id_shelf}`, "get", "");
  },
  async sendShelvesZones(data) {
    return await sendRequest(`/api/update_shelf_sections`, "post", data);
  },
  async sendCustomRequest(url, method, data){
    return await sendRequest(url, method.toLowerCase(), data);
  },
  async getDataRow(id, entity) {
    return await sendRequest(`/api/v1/admin_panel/entity?id=${id}&entity=${entity}`, "post", "");
  },
};
